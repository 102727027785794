import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {
  StripeElementsOptions,
  StripeCardElementOptions,
  loadStripe,
  StripeElements,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
  StripeCardElementChangeEvent,
  StripePaymentElementOptions,
  StripeElementClasses,
  Stripe,
  PaymentMethod,
  StripeError,
} from '@stripe/stripe-js';
import {
  StripeApiService,
  TravellerApiService,
  VerificationApiService,
} from '@fleet/api';
import {
  AddressModel,
  ApiResponse,
  DriverModel,
  IssueModel,
  OperatorModel,
  BrandSettingModel,
  OrganisationModel,
  PaymentMethodModel,
  ProductConfigurationModel,
  TravellerModel,
  IntegrationKeySearchResultModel,
} from '@fleet/model';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  ProductConfig,
  ProductConfigurationService,
} from '@fleet/product-configuration';

import { DateTime } from 'luxon';

@Component({
  selector: 'fleet-stripe-payment',
  standalone: true,
  template: ` <div id="payment-element"></div> `,
  styles: [``],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripePaymentComponent implements OnInit, AfterViewInit {
  @Input() billingAddress: {
    name: string | null;
    email: string | null;
    phone: string | null;
    line1: string | null;
    line2: string | null;
    city: string | null;
    state: string | null;
    postal_code: string | null;
    country: string | null;
  };
  @Input() traveller: TravellerModel;
  @Input() operator: OperatorModel;
  @Input() organisation: OrganisationModel;
  @Input() driver: DriverModel;
  @Input() brandConfig: BrandSettingModel;

  @Output() paymentMethod3dsCreated = new EventEmitter();

  stripeLoaded$ = new BehaviorSubject<boolean>(false);
  publicKey$ = new BehaviorSubject<string | null>(null);
  stripeElementsInstance: StripeElements | null = null;
  stripeElementsEvent: any;
  loadingMessage: string;
  issues: BehaviorSubject<IssueModel[]> = new BehaviorSubject([]);
  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);

  stripe: Stripe | null = null;
  paymentElement: any;

  @Output() confirmationTokenGenerated = new EventEmitter<string>();

  latestStripeFormEvent: BehaviorSubject<any> = new BehaviorSubject(null);

  get status$(): Observable<any> {
    return combineLatest([
      this.latestStripeFormEvent.asObservable(),
      this.loading.asObservable(),
      this.issues.asObservable(),
    ]).pipe(
      map(([event, loading, issues]) => {
        return {
          loading: loading,
          isValid: event?.complete ? true : false,
          isInitialised: this.stripeElementsInstance ? true : false,
          issues: issues,
        };
      })
    );
  }

  constructor(
    private stripeApiService: StripeApiService,
    private travellerApiService: TravellerApiService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private verificationApiService: VerificationApiService
  ) {}

  ngOnInit(): void {
    this.initialiseStripe();
  }

  ngAfterViewInit(): void {}

  initialiseStripe() {
    this.loadStripeScript().then(() => {
      this.stripeLoaded$.next(true);
    });

    this.stripeApiService
      .searchStripeKeys({ integrationType: 'STRIPE', keyType: 'SERVER' })
      .subscribe({
        next: (response: ApiResponse<IntegrationKeySearchResultModel[]>) => {
          if (response.data.length > 0) {
            this.publicKey$.next(response.data[0].publicKey);
          } else {
            this.publicKey$.next('ERROR');
          }
        },
        error: (error) => {
          this.publicKey$.next('ERROR');
        },
      });

    combineLatest([this.stripeLoaded$, this.publicKey$]).subscribe(
      ([stripeLoaded, publicKey]) => {
        if (stripeLoaded && publicKey) {
          //initial loading
          this.initializeStripe(publicKey);
          this.changeDetectorRef.markForCheck();
        }
      }
    );
  }

  loadStripeScript() {
    return new Promise<void>((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject();
        this.handleError({
          message:
            'An error has occurred. Please try again, or contact support for further assistance.',
          type: 'StripeLoadError',
        } as any);
      };
      this.renderer.appendChild(document.body, script);
    });
  }

  async initializeStripe(publicKey: string) {
    const options = {
      mode: 'setup' as const,
      currency: 'usd',
      paymentMethodCreation: 'manual',
      payment_method_types: ['card'],
      appearance: {
        /*...*/
        theme: 'flat',
        variables: {
          colorPrimary: 'blue',
          colorDanger: (this.brandConfig.theme as any).warn,
          colorBackground: 'white',
          borderRadius: '6px',
        },

        rules: {
          '.Input': {
            borderColor: (this.brandConfig.theme as any).accent,
            backgroundColor: '#ffffff', // White background
            borderStyle: 'solid', // Ensure border is shown even when not used
            borderWidth: '1px', // Set border width to 1px
          },
          '.Input--invalid': {
            borderColor: (this.brandConfig.theme as any).warn, //
            color: '#000000',
            borderWidth: '1px', // Ensure invalid input border width is 1px
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)', // Hide shadow
          },
          '.Input:focus': {
            borderWidth: '1px', // Ensure focused input border width is 1px
            borderColor: (this.brandConfig.theme as any).accent,
            color: '#000000',
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)', // Hide shadow
          },
          '.Error': {
            borderColor: (this.brandConfig.theme as any).warn,
            fontSize: '12px', // Error text size
            borderWidth: '1px', // Ensure error border width is 1px
            fontWeight: '500', // Set text weight to 500
          },
          '.Label': {
            fontSize: '14px', // Error text size
            fontWeight: '500', // Make text bold
            color: '#000000',
          },
        },
      },
    } as any;

    this.stripe = await loadStripe(publicKey);

    const elements = this.stripe.elements(options);

    this.paymentElement = elements.create('payment', {
      fields: {
        billingDetails: {
          name: 'never',
          email: 'never',
          address: 'never',
        },
      },
    });

    this.paymentElement.on('loaderror', (event: any) => {
      this.handleError({
        message:
          'An error has occurred. Please try again, or contact support for further assistance.',
        type: 'StripeLoadError',
      } as any);
      this.changeDetectorRef.markForCheck();
    });

    let root = this;
    this.paymentElement.mount('#payment-element');

    this.paymentElement.on('change', function (event: any) {
      if (event.complete) {
        console.log('Form is complete and ready');
      }
      root.latestStripeFormEvent.next(event);

      console.log(JSON.stringify(event));
    });

    this.stripeElementsInstance = elements;

    this.changeDetectorRef.markForCheck();

    setTimeout(() => {
      root.loading.next(false);

      this.changeDetectorRef.markForCheck();
    }, 1500);
  }

  resetCardForm() {
    if (!this.stripeElementsInstance) {
      console.error('Stripe Elements instance is not initialized.');
      return;
    }

    const paymentElement = this.stripeElementsInstance.getElement('payment');
    if (paymentElement) {
      paymentElement.clear();
      console.log('Card form has been reset.');
    } else {
      console.error('Payment element is not found.');
    }

    this.loading.next(false);
  }

  async onSubmit() {
    if (!this.stripeElementsInstance) {
      console.error('Stripe Elements instance is not initialized.');
      return;
    }
    this.loading.next(true);
    this.issues.next([]);
    this.loadingMessage = 'Verifying card';
    this.changeDetectorRef.markForCheck();

    try {
      const { error: submitError } = await this.stripeElementsInstance.submit();
      if (submitError) {
        this.handleError(submitError);
        return;
      }

      const billingDetails = this.billingAddress;

      let x = billingDetails;
      console.log(x);

      const { error, confirmationToken } =
        await this.stripe.createConfirmationToken({
          elements: this.stripeElementsInstance,
          params: {
            return_url: 'https://example.com/order/123/compnlete',
            payment_method_data: {
              billing_details: billingDetails,
            },
          },
        });

      if (error) {
        this.handleError(error);
        return;
      }

      this.confirmationTokenGenerated.emit(confirmationToken.id as string);
      this.loadingMessage = null;
      this.changeDetectorRef.markForCheck();
    } catch (error) {
      this.handleError({
        message:
          'An error has occurred.  Please try again, or contact support for further assistance.',
        type: 'StripeError',
      } as any);
    }

    this.loadingMessage = null;
    this.changeDetectorRef.markForCheck();
  }

  handleError(error: StripeError) {
    console.error('Error:', error);
    const issue: IssueModel = {
      severity: '1',
      type: error.type || 'StripeError',
      alertDescription: error.message,
      message: error.message,
    } as any;

    this.issues.next([issue]);

    this.loading.next(false);
    this.loadingMessage = null;
    this.resetCardForm();

    this.changeDetectorRef.markForCheck();
  }

  get issuesValue() {
    return this.issues.value;
  }

  handleNextAction(paymentMethod: PaymentMethodModel) {
    this.stripe
      .handleNextAction({
        clientSecret: paymentMethod.card.verification.stripeClientSecret,
      })
      .then(({ error, setupIntent }) => {
        if (error) {
          this.setupIntent({
            ...paymentMethod,
            setupIntentId: error?.setup_intent?.id,
          } as any);
        } else {
          this.setupIntent({
            ...paymentMethod,
            setupIntentId: setupIntent.id,
          } as any);
        }
      });
  }

  setupIntent(paymentMethod: PaymentMethodModel) {
    // this.stripeApiService.complete3DSAuthentication(paymentMethod).subscribe({
    //   next: (response: ApiResponse<PaymentMethodModel>) => {
    //     console.log('Setup intent action completed successfully', response);
    //     this.loading.next(false);
    //     this.paymentMethod3dsCreated.next(response.data);
    //   },
    //   error: (error: StripeError[]) => {
    //     if (error.length > 0) {
    //       this.handleError(error[0]);
    //     }
    //     this.changeDetectorRef.markForCheck();
    //     console.error('Error completing setup intent action', error);
    //   },
    // });

    let payload = {
      verificationId: paymentMethod.card.verification.verificationId,
      creditCardVerification: {
        verificationType: paymentMethod.card.verification.verificationType,
        stripeSetupIntentId:
          paymentMethod.card.verification.stripeSetupIntentId,
      },
    } as any;

    this.verificationApiService.verify(payload).subscribe({
      next: (response) => {
        this.loading.next(false);
        //     console.log('Setup intent action completed successfully', response);

        this.paymentMethod3dsCreated.next(response.data);
      },
      error: (error) => {
        if (error.length > 0) {
          this.handleError(error[0]);
        }
        //     console.error('Error completing setup intent action', error);

        this.changeDetectorRef.markForCheck();
      },
    });
  }

  convertToStripeAddress(address: AddressModel): any {
    return {
      line1: (address?.streetNumber ?? '') + ' ' + (address?.streetName ?? ''),
      line2: address?.unitNumber ?? '',
      city: address?.locality ?? '',
      state: address?.regionCode ?? '',
      postal_code: address?.postalCode ?? '',
      country: address?.countryCode ?? 'AU',
    };
  }
}
