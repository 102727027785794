<ng-container *transloco="let t">
  <ng-container [ngSwitch]="mode" *ngIf="config">
    <ng-container *ngSwitchCase="'card'">
      <div
        (click)="cardSelected.emit(paymentMethod)"
        class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card w-52 h-52"
        [ngClass]="{
          'ring ring-inset ring-red-600': hasError,
          'ring ring-inset ring-primary': isDefault
        }"
      >
        <ng-container *ngIf="isDefault">
          <mat-icon
            class="absolute top-0 right-0 mt-3 mr-3 icon-size-7 text-primary"
            [svgIcon]="'heroicons_solid:check-circle'"
          ></mat-icon>
        </ng-container>
        <ng-container
          *ngIf="
            config.paymentType !== 'CASH' &&
            config.paymentType !== 'BANK_DEPOSIT'
          "
        >
          <ng-container *ngIf="config.icons === true">
            <ng-container *ngTemplateOutlet="icons"> </ng-container>
          </ng-container>
        </ng-container>
        <div class="text-md font-medium mt-2 line-clamp-3">
          {{ config.displayOne }}
        </div>
        <div
          *ngIf="
            config?.displayTwo && config?.displayTwo !== config?.displayOne
          "
          class="text-sm text-secondary wrap line-clamp-3"
        >
          {{ config.displayTwo }}
        </div>
        <div
          *ngIf="config.detailsOne"
          class="flex flex-col mt-1 whitespace-normal text-secondary text-sm line-clamp-3"
        >
          <div>
            {{ config.detailsOne }}
          </div>
          <div>
            {{ config.detailsTwo }}
          </div>
        </div>
        <div
          *ngIf="config.detailsThree"
          class="flex flex-col mt-1 whitespace-normal text-secondary text-sm line-clamp-3"
        >
          <div>
            {{ config.detailsThree }}
          </div>
          <div>
            {{ config.detailsFour }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'list'">
      <div
        [ngClass]="{
          'ring-2 ring-red-500': hasError,
          'bg-card rounded-md shadow': shadow
        }"
      >
        <div
          class="relative rounded-md"
          [ngClass]="{
            'ring-2 ring-inset ring-primary': isDefault
          }"
        >
          <ng-container *ngIf="isDefault">
            <mat-icon
              class="absolute top-1 right-1 icon-size-5 text-primary"
              [svgIcon]="'heroicons_solid:check-circle'"
            ></mat-icon>
          </ng-container>
          <div
            *ngIf="config"
            class="flex items-center py-3 justify-between w-full"
            [ngClass]="{
              ' px-4 sm:px-6': padding
            }"
          >
            <div
              class="flex flex-auto flex-wrap gap-2 w-full item-center justify-between"
            >
              <div class="flex flex-row gap-3 items-center">
                <ng-container *ngIf="config.icons">
                  <ng-container *ngTemplateOutlet="icons"> </ng-container>
                </ng-container>

                <div
                  *ngIf="config?.displayOne"
                  class="flex flex-col justify-start gap-1 line-clamp-2 self-center"
                >
                  <div class="text-md font-medium">
                    {{ config.displayOne }}
                  </div>
                  <div
                    *ngIf="
                      config?.displayTwo &&
                      config?.displayTwo !== config?.displayOne
                    "
                    class="flex text-sm font-medium text-gray-500"
                  >
                    {{ config.displayTwo }}
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col line-clamp-2 text-sm font-medium text-gray-500 self-center"
                *ngIf="config.detailsOne"
              >
                <div class="w-full">
                  {{ config.detailsOne }}
                </div>
                <div *ngIf="config.detailsTwo">
                  {{ config.detailsTwo }}
                </div>
              </div>
              <div
                class="flex flex-col line-clamp-2 text-sm font-medium text-gray-500 self-center"
                *ngIf="config.detailsThree"
              >
                <div>
                  {{ config.detailsThree }}
                </div>
                <div *ngIf="config.detailsFour">
                  {{ config.detailsFour }}
                </div>
              </div>

              <div class="items-center">
                <!-- This is a fake news div to mimick the
                spacing that is needed for the status chip -
                that is outside of this div and hugging the
                 right of it - but not having this the text is not center nicely when next to a status -->
              </div>
            </div>
            <div>
              <fleet-status-chip
                *ngIf="config.status"
                class="self-center justify-end cursor-default"
                [status]="config.status"
                fleetToolTip
                [contentTemplate]="
                  config?.verificationInfo ? tooltipTemplate : null
                "
              >
                <ng-template #tooltipTemplate>
                  <div
                    class="text-xs flex flex-col space-y-2 rounded-md px-2 py-1 bg-gray-700 text-white"
                  >
                    <div>
                      {{ t('paymentMethodListItem.verificationStatus') }}:
                      {{
                        config.verificationInfo?.cardVerificationStatus
                          | titleCaseAndClean
                      }}
                    </div>
                    <div>
                      {{ t('paymentMethodListItem.verificationType') }}:
                      {{
                        config.verificationInfo?.cardVerificationType
                          | titleCaseAndClean
                      }}
                    </div>
                    <div
                      *ngIf="
                        config.verificationInfo.cardVerificationStatus ===
                        'VERIFIED'
                      "
                    >
                      {{ t('paymentMethodListItem.dateVerified') }}:
                      {{ config.verificationInfo?.cardVerified }}
                    </div>
                    <div>
                      {{ t('paymentMethodListItem.threeDsEnrolled') }}:
                      {{ config.verificationInfo?.cardThreeDsEnrolled }}
                    </div>
                  </div>
                </ng-template>
              </fleet-status-chip>
            </div>
            <div
              class="pl-3"
              *ngIf="actions && actions.length > 0 && !disableActions"
            >
              <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
                <button
                  *ngFor="let action of actions"
                  mat-menu-item
                  (click)="actionSelected.emit(action.value)"
                >
                  {{ action.label }}
                </button>
              </mat-menu>
              <button
                type="button"
                mat-icon-button
                [matMenuTriggerFor]="appMenu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'condensed'">
      <div class="flex flex-row gap-3">
        <ng-container *ngIf="config.icons">
          <ng-container *ngTemplateOutlet="icons"> </ng-container>
        </ng-container>
        <div
          *ngIf="config?.displayOne"
          class="flex flex-col justify-start gap-1 line-clamp-2 self-center"
        >
          <div class="text-md font-medium">
            {{ config.displayOne }}
          </div>
          <div
            *ngIf="config?.displayTwo"
            class="flex text-sm font-medium text-gray-500"
          >
            {{ config.displayTwo }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'condensed_2'">
      <div class="flex flex-row gap-3 w-full items-center">
        <mat-icon
          [svgIcon]="config.cardType | creditCard : 'svg'"
          class="icon-size-5"
        ></mat-icon>
        <div class="text-sm">
          {{ config.displayOne }}
        </div>
      </div>
    </ng-container>

    <ng-template #icons>
      <ng-container [ngSwitch]="config.paymentType">
        <mat-icon
          *ngSwitchCase="'ORGANISATION_ACCOUNT'"
          style="width: 30px; height: 30px"
          [matTooltip]="t('paymentMethodListItem.organisationAccount')"
        >
          account_balance
        </mat-icon>
        <div
          *ngSwitchCase="'DIRECT_DEBIT_BANK_ACCOUNT'"
          class="w-10 text-xs items-center"
          [matTooltip]="t('paymentMethodListItem.directDebit')"
        >
          {{ t('paymentMethodListItem.directDebit') }}
        </div>
        <div
          *ngSwitchCase="'SETTLEMENT_BANK_ACCOUNT'"
          class="flex flex-wrap w-26 font-bold items-center"
          [matTooltip]="t('paymentMethodListItem.settlementAccount')"
        >
          {{ t('paymentMethodListItem.settlementAccount') }}
        </div>
        <mat-icon
          *ngSwitchCase="'STORED_CARD'"
          [svgIcon]="config.cardType | creditCard : 'svg'"
          style="width: 30px; height: 30px"
          [matTooltip]="config.cardType | titleCaseAndClean"
        >
        </mat-icon>
        <mat-icon
          *ngSwitchCase="'CASH'"
          style="width: 30px; height: 30px"
          [matTooltip]="t('paymentMethodListItem.cash')"
        >
          monetization_on
        </mat-icon>
        <mat-icon
          *ngSwitchCase="'BANK_DEPOSIT'"
          style="width: 30px; height: 30px"
          [matTooltip]="t('paymentMethodListItem.bankDeposit')"
        >
          account_balance
        </mat-icon>
        <!-- <mat-icon
          *ngSwitchDefault
          style="width: 30px; height: 30px"
          [matTooltip]="''"
        >
          receipt
        </mat-icon> -->
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
