import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaymentMethodCreateEditComponent } from './payment-method-create-edit.component';

import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { SidebarFormLayoutModule } from '@fleet/layout';
import { ProgressButtonModule } from '@fleet/shared';
import {
  ActionButtonsModule,
  DialogLayoutModule,
  SectionModule,
} from '@fleet/ui';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { ContractSelectorModule } from '@fleet/contract-shared';
import { AccountModelFormModule } from '../account-model-form/account-model-form.module';
import { BraintreeHostedFieldsModule } from '../braintree-hosted-fields/braintree-hosted-fields.module';
import { StripePaymentComponent } from '../stripe-payment/stripe-payment.component';
import { LocationFormModule } from '@fleet/location';
import { BillingAddressComponent } from '../billing-address/billing-address.component';

@NgModule({
  declarations: [PaymentMethodCreateEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    SidebarFormLayoutModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ActionButtonsModule,
    ProgressButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    ContractSelectorModule,
    AccountModelFormModule,
    DialogLayoutModule,
    BraintreeHostedFieldsModule,
    StripePaymentComponent,
    LocationFormModule,
    BillingAddressComponent,
    SectionModule,
  ],
  exports: [PaymentMethodCreateEditComponent],
})
export class PaymentMethodCreateEditModule {}
