<ng-container
  *ngIf="paymentMethodCreateEditState | async as paymentMethodState"
>
  <ng-container *ngIf="exteneralPaymentState | async as externalState">
    <ng-container [ngSwitch]="mode">
      <ng-container *ngSwitchCase="'SIDEBAR'">
        <fleet-sidebar-form-layout
          class="flex-auto gap-3"
          [title]="paymentMethodState.title"
          (closeSidebar)="onCancel.emit()"
        >
          <div
            class="absolute inset-0 flex items-center justify-center"
            *ngIf="paymentMethodState?.loading || externalState?.loading"
          >
            <div class="flex flex-col items-center gap-2">
              <mat-spinner diameter="24"></mat-spinner>
            </div>
          </div>

          <div class="mt-8"></div>

          <div
            class="px-6 flex flex-col"
            [ngClass]="{
              'opacity-30 pointer-events-none':
                paymentMethodState?.loading || externalState?.loading
            }"
            *ngIf="paymentTypeControl && !paymentMethodType"
          >
            <mat-form-field>
              <mat-label>Payment method type</mat-label>

              <mat-select
                [disabled]="paymentMethodState.paymentMethod !== null"
                [formControl]="paymentTypeControl"
              >
                <mat-option
                  *ngFor="let type of PAYMENT_TYPES"
                  [value]="type.value"
                >
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <form
            class="flex flex-col flex-auto"
            [formGroup]="paymentMethodForm"
            (ngSubmit)="submit()"
            [ngClass]="{
              'opacity-30 pointer-events-none':
                paymentMethodState?.loading || externalState?.loading
            }"
            *ngIf="paymentMethodForm && paymentTypeControl.value"
          >
            <div
              class="px-6 flex flex-col"
              *ngIf="payment || paymentTypeControl?.value !== 'CREDIT_CARD'"
            >
              <mat-form-field *ngIf="paymentMethodForm.get('displayName')">
                <mat-label>Display name</mat-label>
                <input
                  matInput
                  placeholder="Display name"
                  formControlName="displayName"
                />
                <mat-error
                  *ngIf="
                    paymentMethodForm.get('displayName').hasError('required')
                  "
                >
                  Payment method name is required</mat-error
                >
                <mat-error
                  *ngIf="
                    paymentMethodForm.get('displayName').hasError('pattern')
                  "
                >
                  Please enter a valid value using only letters, spaces, and
                  apostrophes</mat-error
                >
              </mat-form-field>

              <fleet-account-model-form
                *ngIf="paymentMethodForm.get('account')"
                [padding]="false"
                formControlName="account"
                [paymentType]="paymentTypeControl?.value"
              >
              </fleet-account-model-form>

              <fleet-contract-selector
                *ngIf="!payment && paymentMethodForm.get('contractId')"
                formControlName="contractId"
                [required]="true"
                [organisationId]="
                  organisation ? organisation.organisationId : null
                "
                [mode]="'id'"
              >
              </fleet-contract-selector>
            </div>

            <div
              *ngIf="brandConfig && paymentTypeControl?.value === 'CREDIT_CARD'"
              class="flex flex-col px-6"
              [ngClass]="{
                'opacity-30 pointer-events-none':
                  paymentMethodState?.loading || externalState?.loading,
                '-mt-5': payment || paymentTypeControl?.value !== 'CREDIT_CARD'
              }"
            >
              <mat-form-field
                [ngClass]="{
                  hidden:
                    apiLoadError ||
                    paymentMethodState?.issues?.length > 0 ||
                    braintreeStatus?.issues.length > 0 ||
                    stripeStatus?.issues.length > 0
                }"
                class="w-full"
                *ngIf="
                  paymentMethodForm.get('displayName') && this.organisation
                "
              >
                <mat-label>Display name</mat-label>
                <input
                  matInput
                  placeholder="Display name"
                  formControlName="displayName"
                />
                <mat-error
                  *ngIf="
                    paymentMethodForm.get('displayName').hasError('required')
                  "
                >
                  Payment method name is required</mat-error
                >
                <mat-error
                  *ngIf="
                    paymentMethodForm.get('displayName').hasError('pattern')
                  "
                >
                  Please enter a valid value using only letters, spaces, and
                  apostrophes</mat-error
                >
              </mat-form-field>

              <fleet-section
                [title]="'Billing Details'"
                class="w-full"
                [hasDivider]="true"
                [contentPadding]="'mt-3'"
                [ngClass]="{
                  hidden:
                    apiLoadError ||
                    paymentMethodState?.issues?.length > 0 ||
                    braintreeStatus?.issues.length > 0 ||
                    stripeStatus?.issues.length > 0
                }"
              >
                <fleet-billing-address
                  *ngIf="paymentProvider"
                  slot="main"
                  [type]="paymentProvider"
                  [traveller]="traveller"
                  [driver]="driver"
                  [organisation]="organisation"
                  [formControl]="billingAddressControl"
                  [operator]="operator"
                >
                </fleet-billing-address>
              </fleet-section>

              <fleet-section
                class="mt-3"
                [title]="'Card Details'"
                class="w-full"
                [hasDivider]="false"
                [contentPadding]="'my-3'"
                [ngClass]="{
                  hidden:
                    apiLoadError ||
                    paymentMethodState?.issues?.length > 0 ||
                    braintreeStatus?.issues.length > 0 ||
                    stripeStatus?.issues.length > 0
                }"
              >
                <ng-container slot="main">
                  <fleet-stripe-payment
                    *ngIf="!payment && paymentProvider === 'STRIPE'"
                    [billingAddress]="billingAddressControl?.value"
                    [brandConfig]="brandConfig"
                    [traveller]="traveller"
                    [operator]="operator"
                    [driver]="driver"
                    [organisation]="organisation"
                    (confirmationTokenGenerated)="
                      createPaymentMethodFromStripe($event)
                    "
                    (paymentMethod3dsCreated)="paymentMethod3dsCreated($event)"
                  >
                  </fleet-stripe-payment>

                  <fleet-braintree-hosted-fields
                    *ngIf="!payment && paymentProvider === 'BRAINTREE'"
                    [driver]="driver"
                    [traveller]="traveller"
                    [operator]="operator"
                    (nonceGenerated)="createPaymentMethodFromNonce($event)"
                  ></fleet-braintree-hosted-fields>
                </ng-container>
              </fleet-section>
            </div>
            <div class="flex-grow"></div>

            <ng-container
              *ngIf="
                paymentMethodState?.issues &&
                (braintreeStatus?.issues?.length === 0 || !braintreeStatus) &&
                (stripeStatus?.issues?.length === 0 || !stripeStatus)
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  error;
                  context: {
                    $implicit: {
                      issues: paymentMethodState?.issues
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>

            <ng-container *ngIf="braintreeStatus?.issues">
              <ng-container
                *ngTemplateOutlet="
                  error;
                  context: {
                    $implicit: {
                      issues: braintreeStatus.issues
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>

            <ng-container *ngIf="stripeStatus?.issues">
              <ng-container
                *ngTemplateOutlet="
                  error;
                  context: {
                    $implicit: {
                      issues: stripeStatus.issues
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>

            <fleet-action-buttons>
              <button mat-button type="button" (click)="cancel()">
                Cancel
              </button>
              <ng-container
                *ngIf="
                  paymentTypeControl?.value !== 'CREDIT_CARD' && organisation;
                  else externalPaymentSubmit
                "
              >
                <ng-container
                  *ngTemplateOutlet="
                    submitButton;
                    context: {
                      $implicit: {
                        state: paymentMethodState,
                        disabled:
                          !paymentMethodForm.valid ||
                          paymentMethodState.loading ||
                          !paymentTypeControl.value,

                      }
                    }
                  "
                >
                </ng-container>
              </ng-container>
            </fleet-action-buttons>
          </form>
        </fleet-sidebar-form-layout>
      </ng-container>
      <ng-container *ngSwitchCase="'DIALOG'">
        <fleet-dialog-layout
          [title]="'Create Payment Method'"
          [iconColour]="'primary'"
          [icon]="'info'"
        >
          <ng-container slot="main">
            <fleet-braintree-hosted-fields
              [ngClass]="{
                'opacity-30 pointer-events-none':
                  paymentMethodState?.loading || externalState?.loading
              }"
              *ngIf="!payment && paymentProvider === 'BRAINTREE'; else isStripe"
              class="px-6 mt-5"
              [driver]="driver"
              [traveller]="traveller"
              [operator]="operator"
              (braintreeError)="brainTreeError($event)"
              (nonceGenerated)="createPaymentMethodFromNonce($event)"
            ></fleet-braintree-hosted-fields>

            <ng-template #isStripe>
              <fleet-stripe-payment
                [ngClass]="{
                  'opacity-30 pointer-events-none':
                    paymentMethodState?.loading || externalState?.loading
                }"
                [billingAddress]="billingAddressControl?.value"
                [brandConfig]="brandConfig"
                [traveller]="traveller"
                [organisation]="organisation"
                [operator]="operator"
                [driver]="driver"
                (confirmationTokenGenerated)="
                  createPaymentMethodFromStripe($event)
                "
                (paymentMethod3dsCreated)="paymentMethod3dsCreated($event)"
                *ngIf="!payment"
              >
              </fleet-stripe-payment>
            </ng-template>
          </ng-container>
          <ng-container slot="action">
            <ng-container *ngIf="externalState">
              <ng-container
                *ngTemplateOutlet="
                  submitButton;
                  context: {
                    $implicit: {
                      state: externalState,
                      disabled:
                        !paymentTypeControl.value ||
                        !paymentMethodForm.valid ||
                        (paymentTypeControl.value === 'CREDIT_CARD' &&
                          (paymentProvider === 'BRAINTREE'
                            ? !braintreeStatus?.isValid
                            : !stripeStatus?.isValid ||
                              !billingAddressControl.valid)),
                      brandConfig: brandConfig
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </fleet-dialog-layout>
      </ng-container>
      <ng-container *ngSwitchCase="'FORM_ONLY'">
        <div
          class="absolute inset-0 flex items-center justify-center"
          *ngIf="paymentMethodState?.loading || externalState?.loading"
        >
          <div class="flex flex-col items-center gap-2">
            <mat-spinner diameter="24"></mat-spinner>
          </div>
        </div>

        <form
          [ngClass]="{
            'opacity-30 pointer-events-none':
              paymentMethodState?.loading || externalState?.loading
          }"
          class="flex flex-col flex-auto"
          *ngIf="paymentMethodForm"
          [formGroup]="paymentMethodForm"
        >
          <mat-form-field *ngIf="paymentMethodForm.get('displayName')">
            <mat-label>Display name</mat-label>
            <input
              required
              matInput
              placeholder="Display name"
              formControlName="displayName"
            />
            <mat-error
              *ngIf="paymentMethodForm.get('displayName').hasError('required')"
            >
              Payment method name is required</mat-error
            >
            <mat-error
              *ngIf="paymentMethodForm.get('displayName').hasError('pattern')"
            >
              Please enter a valid value using only letters, spaces, and
              apostrophes</mat-error
            >
          </mat-form-field>
        </form>

        <fleet-billing-address
          [ngClass]="{
            hidden:
              apiLoadError ||
              paymentMethodState?.issues?.length > 0 ||
              braintreeStatus?.issues.length > 0 ||
              stripeStatus?.issues.length > 0
          }"
          *ngIf="paymentProvider"
          [type]="paymentProvider"
          [traveller]="traveller"
          [driver]="driver"
          [organisation]="organisation"
          [formControl]="billingAddressControl"
          [operator]="operator"
        >
        </fleet-billing-address>

        <fleet-braintree-hosted-fields
          [ngClass]="{
            'opacity-30 pointer-events-none':
              paymentMethodState?.loading || externalState?.loading,
            hidden:
              apiLoadError ||
              paymentMethodState?.issues?.length > 0 ||
              braintreeStatus?.issues.length > 0 ||
              stripeStatus?.issues.length > 0
          }"
          *ngIf="!payment && paymentProvider === 'BRAINTREE'"
          class="px-6 mt-5"
          [driver]="driver"
          [traveller]="traveller"
          [operator]="operator"
          (braintreeError)="brainTreeError($event)"
          (nonceGenerated)="createPaymentMethodFromNonce($event)"
        ></fleet-braintree-hosted-fields>

        <fleet-stripe-payment
          *ngIf="!payment && paymentProvider === 'STRIPE'"
          [ngClass]="{
            'opacity-30 pointer-events-none':
              paymentMethodState?.loading || externalState?.loading,
            hidden:
              apiLoadError ||
              paymentMethodState?.issues?.length > 0 ||
              braintreeStatus?.issues.length > 0 ||
              stripeStatus?.issues.length > 0
          }"
          [billingAddress]="billingAddressControl?.value"
          [brandConfig]="brandConfig"
          [traveller]="traveller"
          [organisation]="organisation"
          [operator]="operator"
          [driver]="driver"
          (confirmationTokenGenerated)="createPaymentMethodFromStripe($event)"
          (paymentMethod3dsCreated)="paymentMethod3dsCreated($event)"
        >
        </fleet-stripe-payment>
      </ng-container>
    </ng-container>

    <ng-template #externalPaymentSubmit>
      <ng-container *ngIf="externalState">
        <ng-container
          *ngTemplateOutlet="
            submitButton;
            context: {
              $implicit: {
                state: externalState,
                disabled:
                  !paymentTypeControl.value ||
                  !paymentMethodForm.valid ||
                  (paymentProvider === 'BRAINTREE'
                    ? !braintreeStatus?.isValid || !billingAddressControl.valid
                    : !stripeStatus?.isValid || !billingAddressControl.valid),
                brandConfig: brandConfig
              }
            }
          "
        >
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #submitButton let-data>
      <fleet-progress-button
        class="ml-3"
        [state]="data.state"
        [disabled]="data.disabled"
        type="button"
        (onClick)="
          paymentTypeControl.value === 'CREDIT_CARD' &&
          paymentProvider === 'BRAINTREE'
            ? submitBraintreePaymentMethod()
            : paymentTypeControl.value === 'CREDIT_CARD' &&
              paymentProvider === 'STRIPE'
            ? submitStripePaymentMethod()
            : submit()
        "
      >
      </fleet-progress-button>
    </ng-template>

    <ng-template #error let-data>
      <fuse-alert
        *ngFor="let alert of data?.issues | alertsFromIssues"
        class="px-6 py-3"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </fuse-alert>
    </ng-template>
  </ng-container>
</ng-container>
