export const logoutNavigationMenu = {
  id: 'logout',
  title: 'logout',
  type: 'basic',
  icon: 'heroicons_outline:logout',
  link: '/auth/logout',
};

export const driverSettingsNavigationMenu = [
  {
    id: 'status',
    icon: 'heroicons_outline:flag',
    title: 'Status',
    description: 'Update driver status',
    link: './status',
    securityFunctions: ['DRIVER_STATUS:read'],
  },
  {
    id: 'personal',
    icon: 'heroicons_outline:user-circle',
    title: 'Personal Details',
    description: 'Personal Details, Change Username, phone',
    link: './personal',
    securityFunctions: ['DRIVER_PERSONAL:read'],
  },
  {
    id: 'licence',
    icon: 'heroicons_outline:identification',
    title: 'Driver Licence',
    description: 'Name, Licence and Address Details',
    link: './licence',
    securityFunctions: ['DRIVER_LICENCE:read'],
  },
  {
    id: 'authority',
    icon: 'heroicons_outline:shield-check',
    title: 'Driver Authority',
    description: 'Manage regulatory authority details',
    link: './authority',
    securityFunctions: ['DRIVER_AUTHORITY:read'],
  },
  {
    id: 'business',
    icon: 'heroicons_outline:briefcase',
    title: 'Business Details',
    description: 'ABN and Legal Business Name',
    link: './business',
    securityFunctions: ['DRIVER_ABN:read'],
  },
  {
    id: 'plan-billing',
    icon: 'heroicons_outline:credit-card',
    title: 'Plan & Billing',
    description:
      'Manage subscription plan, payment method and billing information',
    link: './plan-and-billing',
    securityFunctions: [
      'DRIVER_ABN:read',
      'DRIVER_BILLING:read',
      'DRIVER_AGREEMENTS:read',
    ],
  },
  {
    id: 'settlement',
    icon: 'heroicons_outline:currency-dollar',
    title: 'Settlement',
    description: 'Manage bank account and settlement settlement information',
    link: './settlement-accounts',
    securityFunctions: ['DRIVER_SETTLEMENT_ACCOUNTS:read'],
  },
  {
    id: 'conditions',
    icon: 'heroicons_outline:clipboard-list',
    title: 'Dispatch Conditions',
    description: 'Manage driver conditions and preferences for dispatch',
    link: './conditions',
    securityFunctions: ['DRIVER_CONDITIONS:read'],
  },
  {
    id: 'security',
    icon: 'heroicons_outline:lock-closed',
    title: 'Security',
    description: 'Manage password and 2-step verification preferences',
    link: './security',
    securityFunctions: ['DRIVER_SECURITY_PREFERENCES:read'],
  },

  {
    id: 'history',
    icon: 'heroicons_outline:collection',
    title: 'Driving Record',
    description: 'Driving history from regulatory authorities',
    link: './history',
    securityFunctions: ['DRIVER_RECORD:read'],
  },

  {
    id: 'terminal',
    icon: 'heroicons_outline:device-mobile',
    title: 'Payment Terminal',
    description: 'Driver payment terminal information',
    link: './terminal',
    securityFunctions: ['DRIVER_ASSET:read'],
  },
  {
    id: 'certificate',
    icon: 'heroicons_outline:document-report',
    title: 'Certificate Records',
    description:
      'Maintain driver certificates such as flu vaccinations, working with children, criminal history etc.',

    link: './certificate',
    securityFunctions: ['DRIVER_CERTIFICATE:read'],
  },

  {
    id: 'diagnostic',
    icon: 'heroicons_outline:chart-square-bar',
    title: 'Diagnostics',
    description: 'Driver diagnostics',
    link: './diagnostic',
    securityFunctions: ['DRIVER_DIAGNOSTICS:read'],
  },

  {
    id: 'exteneral',
    icon: 'link',
    title: 'External System Reference',
    description: 'Change the external system reference of this driver',
    link: './external',
    securityFunctions: ['DRIVER_EXTERNAL_SYSTEM:read'],
  },
];

export const driverSettingsNavigationForMainMenu =
  driverSettingsNavigationMenu.map((navItem: any) => {
    return {
      ...navItem,
      link: 'driver/settings' + navItem.link.replace('.', ''),
      type: 'basic',
    };
  });
